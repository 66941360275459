import React from 'react'
import TagBox from 'devextreme-react/tag-box'

export default class ApplicationTagBoxComponent extends React.Component {
	constructor(props) {
		super(props)
		this.onValueChanged = this.onValueChanged.bind(this)
	}

	onValueChanged(e) {
		this.props.data.setValue(e.value)
	}

	render() {
		return (
			<TagBox
				dataSource={this.props.data.column.lookup.dataSource}
				{...(typeof this.props.data.value !== 'undefined'
					? { defaultValue: Object.keys(this.props.data.value).map(value => parseInt(value)) }
					: {})}
				valueExpr="ApplicationID"
				displayExpr="ApplicationName"
				onValueChanged={this.onValueChanged}
			/>
		)
	}
}
