//
// SocketContext.js
//
import React from 'react'
import socketIO from 'socket.io-client'

let _socket = null
let _socket_auth = false
export const socketGet = () => {
	return _socket
}

export const SocketContext = React.createContext({})

export const socketConnect = token => {
	if (_socket) {
		if (_socket_auth) {
			return
		}
		_socket.disconnect()
	}
	console.log('Socket token:', token)
	if (token) {
		console.log('Socket connect with token :)')
		_socket = socketIO.connect(process.env.REACT_APP_SOCKET_URL, { query: `auth_token=${token}` })
		_socket_auth = true
		return
	}
	console.log('Socket connect without token :(')
	_socket = socketIO.connect(process.env.REACT_APP_SOCKET_URL)
	_socket_auth = false
}

socketConnect(localStorage.getItem('jwt_access_token'))
