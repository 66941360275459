import React from 'react'
import DataGrid, { Editing, Scrolling, RemoteOperations, Column } from 'devextreme-react/data-grid'
import { DataView, DataStyles } from 'app/data-grid'
import apiService from 'app/services/apiService'

const dataStore = apiService.createDataCustomStore('ApplicationID', 'applications')

const Applications = () => {
	const classes = DataStyles()

	return (
		<DataView classes={classes} icon={`apps`} title="Applications">
			<DataGrid
				elementAttr={{
					id: 'gridContainer'
				}}
				style={{
					width: '100%',
					height: '100%'
				}}
				dataSource={dataStore}
				wordWrapEnabled={true}
				showBorders={true}
			>
				<Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} />

				<RemoteOperations groupPaging={true} />
				<Scrolling mode="virtual" />

				<Column dataField="ApplicationID" caption="ID" dataType="number" width={75} allowEditing={false} />
				<Column dataField="ApplicationName" caption="Name" dataType="string" />
			</DataGrid>
		</DataView>
	)
}

export default Applications
