import FuseUtils from '@fuse/utils'
import AppContext from 'app/AppContext'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { matchRoutes } from 'react-router-config'
import { withRouter } from 'react-router-dom'
import { getCookie, removeCookie } from 'tiny-cookie'

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props)
		const { routes } = context
		this.state = {
			accessGranted: true,
			routes
		}
	}

	componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props
		const { pathname } = location

		const matched = matchRoutes(state.routes, pathname)[0]

		return {
			accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true
		}
	}

	redirectRoute() {
		const { location, userRole, history } = this.props
		const { pathname, state } = location
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/'

		/**
		 * User is logged out
		 * @redirect to Login Page
		 */
		if (!userRole || userRole.length === 0) {
			if (getCookie('identifier') && !getCookie('prev_url')) {
				removeCookie('identifier')
			}
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname }
			})
		} else {
			/**
			 * User must be on unAuthorized page or just logged in
			 * @redirect to dashboard or redirectUrl
			 */
			history.push({
				pathname: redirectUrl
			})
		}
	}

	render() {
		return this.state.accessGranted ? <>{this.props.children}</> : null
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role
	}
}

FuseAuthorization.contextType = AppContext

export default withRouter(connect(mapStateToProps)(FuseAuthorization))
