import React, { useState } from 'react'
import DataGrid, {
	Editing,
	Scrolling,
	RemoteOperations,
	Column,
	Lookup,
	SearchPanel,
	RequiredRule,
	EmailRule,
	HeaderFilter,
	FilterPanel,
	FilterRow,
	Paging,
	Pager,
	GroupPanel,
	AsyncRule,
	ColumnChooser
} from 'devextreme-react/data-grid'
import { DataView, DataStyles } from 'app/data-grid'
import apiService from 'app/services/apiService'
import ApplicationTagBoxComponent from './ApplicationTagBoxComponent.js'
import { Button } from '@material-ui/core'

const dataSource = apiService.createDataCustomStore('UserID', 'users')
const applications = apiService.createRowCustomStore('ApplicationID', 'applications')
const companies = apiService.createRowCustomStore('CompanyID', 'companies')
const departments = apiService.createRowCustomStore('DepartmentID', 'departments')
const users = apiService.createRowCustomStore('UserID', 'users')

const cellApplicationsTemplate = (container, options) => {
	const noBreakSpace = '\u00A0',
		text = Object.keys(options.value || [])
			.map(k => {
				return options.value[k]
			})
			.join(', ')
	container.textContent = text || noBreakSpace
	container.title = text
}

const cellIDTemplate = (container, options) => {
	container.textContent = Object.keys(options.data).length !== 0 ? options.value : 'Auto Generated'
}

const Users = () => {
	const classes = DataStyles()

	const asyncValidation = params => {
		const { data, value } = params
		const { UserID } = data

		// if (UserID) {
		// 	return true
		// }
		// eslint-disable-next-line react/no-unused-state
		return apiService.sendRequest(value, UserID)
	}

	const [isAdding, setIsAdding] = useState(true)

	const onInitNewRow = e => {
		e.data.IsActive = true
		e.data.IsAzureSSO = false
		e.data.Is2FAConfigured = false
		setIsAdding(true)
	}

	const onEditStart = e => {
		setIsAdding(false)
	}

	const onEditStop = e => {
		setIsAdding(true)
	}

	return (
		<DataView classes={classes} icon={`people`} title="Users">
			<DataGrid
				style={{
					width: '100%',
					height: '100%'
				}}
				key="id"
				id="gridContainer"
				dataSource={dataSource}
				renderAsync
				showBorders
				showColumnLines
				allowColumnResizing
				columnResizingMode="widget"
				columnAutoWidth={false}
				columnMinWidth={50}
				columnWidth={150}
				repaintChangesOnly
				onInitNewRow={onInitNewRow}
				onEditingStart={onEditStart}
				onEditCanceled={onEditStop}
				onSaved={onEditStop}
			>
				<GroupPanel visible />
				<Editing mode="form" useIcons allowUpdating allowDeleting allowAdding />
				<HeaderFilter visible />
				<FilterPanel visible />
				<FilterRow visible />
				<RemoteOperations filtering paging sorting summary />
				<Paging defaultPageSize={100} />
				<Pager visible displayMode="full" showInfo />
				<Scrolling mode="virtual" renderAsync rowRenderingMode="virtual" />
				<SearchPanel visible />
				<ColumnChooser enabled allowSearch={false} />
				<Column
					editCellTemplate={cellIDTemplate}
					dataField="UserID"
					dataType="number"
					caption="User"
					width={75}
					fixed
					allowEditing={false}
				/>
				<Column dataField="LastLogin" dataType="date" format={`dd-M-yyyy`} allowEditing={false} />
				<Column dataField="Username" dataType="string">
					<RequiredRule />
					<EmailRule message="Username is not a valid email address" />
					<AsyncRule message="Username has already been used" validationCallback={asyncValidation} />
				</Column>
				<Column dataField="FirstName" dataType="string">
					<RequiredRule />
				</Column>
				<Column dataField="LastName" dataType="string">
					<RequiredRule />
				</Column>
				<Column dataField="FathersName" dataType="string" visible={false} />
				<Column dataField="JobTitle" dataType="string" visible={false} />
				<Column dataField="BirthDate" dataType="date" visible={false} format={`dd-M-yyyy`} />
				<Column dataField="EmploymentStartDate" dataType="date" visible={false} format={`dd-M-yyyy`} />
				<Column dataField="ReportsTo" dataType="integer" visible={false}>
					<Lookup dataSource={users} valueExpr="UserID" displayExpr="Username" />
				</Column>
				<Column dataField="IsAzureSSO" dataType="boolean" caption="Azure Enabled" />
				<Column dataField="IsActive" dataType="boolean" caption="Active" />
				<Column
					allowEditing={!isAdding}
					dataField="Is2FAConfigured"
					dataType="boolean"
					caption="2FA Configured"
				/>
				<Column
					dataField="applications"
					caption="Applications"
					width={200}
					allowSorting={false}
					editCellComponent={ApplicationTagBoxComponent}
					cellTemplate={cellApplicationsTemplate}
				>
					<Lookup dataSource={applications} valueExpr="ApplicationID" displayExpr="ApplicationName" />
					<RequiredRule />
				</Column>
				<Column dataField="CompanyID" caption="Company">
					<Lookup dataSource={companies} valueExpr="CompanyID" displayExpr="CompanyDescription" />
					<RequiredRule />
				</Column>
				<Column dataField="DepartmentID" caption="Department">
					<Lookup dataSource={departments} valueExpr="DepartmentID" displayExpr="DepartmentDescription" />
					<RequiredRule />
				</Column>
				<Column type="buttons" fixed renderAsync={false} width={80}>
					<Button name="delete" />
					<Button name="save" />
					<Button name="cancel" />
				</Column>
			</DataGrid>
		</DataView>
	)
}

export default Users
