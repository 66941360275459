import Companies from './Companies'
import globalConfig from '../../fuse-configs/globalConfig'

const CompaniesConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['admin'],
	routes: [
		{
			path: '/companies',
			component: Companies
		}
	]
}

export default CompaniesConfig
