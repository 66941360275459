import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { logoutUser } from 'app/auth/store/userSlice'
import FuseAnimate from '@fuse/core/FuseAnimate'
import FusePageCarded from '@fuse/core/FusePageCarded'

class DataView extends Component {
	render() {
		return (
			<FusePageCarded
				classes={{
					root: 'w-full',
					contentWrapper: 'p-0',
					header: 'h-72 min-h-72 sm:h-72 sm:min-h-72',
					contentCard: 'rounded-none',
					content: 'flex flex-col h-full p-0'
				}}
				header={
					<div className="flex flex-1 items-center justify-between p-8 sm:p-24 relative">
						<div className="flex flex-shrink items-center sm:w-224">
							<div className="flex items-center">
								<FuseAnimate animation="transition.expandIn" delay={300}>
									<Icon className="text-32">{this.props.icon}</Icon>
								</FuseAnimate>
								<FuseAnimate animation="transition.slideLeftIn" delay={300}>
									<Typography variant="h6" className="mx-12 hidden sm:flex">
										{this.props.title}
									</Typography>
								</FuseAnimate>
							</div>
						</div>
					</div>
				}
				content={this.props.children}
				innerScroll
			/>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser
		},
		dispatch
	)
}

export default connect(null, mapDispatchToProps)(DataView)
