import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useState } from 'react'
import { LoginView, LoginStyles } from 'app/auth'
import MicrosoftLoginTab from './tabs/MicrosoftLoginTab'
import SingleLoginTab from './tabs/SingleLoginTab'

function Login() {
	const classes = LoginStyles()
	const [selectedTab, setSelectedTab] = useState(0)

	function handleTabChange(event, value) {
		setSelectedTab(value)
	}

	return (
		<LoginView classes={classes}>
			<Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" className="w-full mb-32">
				<Tab
					icon={
						<img
							className="h-40"
							src="assets/images/logos/microsoft-azure.svg"
							alt="Microsoft Azure Login"
						/>
					}
					className="min-w-0"
					label="Microsoft"
				/>

				<Tab
					icon={<img className="h-40" src="assets/images/logos/sso.svg" alt="Single Sign on" />}
					className="min-w-0"
					label="Login"
				/>
			</Tabs>

			{selectedTab === 0 && <MicrosoftLoginTab />}
			{selectedTab === 1 && <SingleLoginTab />}
		</LoginView>
	)
}

export default Login
