import FuseAnimate from '@fuse/core/FuseAnimate'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { Component } from 'react'
import { getCookie, removeCookie } from 'tiny-cookie'
import { withRouter } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import { clearMessages } from './store/loginSlice'
import { bindActionCreators } from '@reduxjs/toolkit'

function ucfirst(str) {
	if (!str) return str
	return str.charAt(0).toUpperCase() + str.slice(1)
}

class LoginView extends Component {
	componentDidMount() {
		if (getCookie('prev_url')) {
			removeCookie('prev_url')
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const timer = setTimeout(() => {
			if (this.props.login.messages.length) {
				this.props.clearMessages()
			}
		}, 25000)
		return () => clearTimeout(timer)
	}

	render() {
		const classes = this.props.classes
		const identifier = ucfirst(getCookie('identifier')) ?? 'ID'

		return (
			<div
				className={clsx(
					classes.root,
					'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
				)}
			>
				<FuseAnimate animation="transition.expandIn">
					<div className="flex w-full max-w-400 md:max-w-3xl rounded-12 shadow-2xl overflow-hidden">
						<Card
							className={clsx(
								classes.leftSection,
								'flex flex-col w-full max-w-sm items-center justify-center shadow-0'
							)}
							square
						>
							{this.props.login.messages.length > 0 &&
								this.props.login.messages.map((message, index) => (
									<Alert
										variant="filled"
										key={index}
										severity="success"
										className="rounded-none w-full"
									>
										{message}
									</Alert>
								))}

							<CardContent className="flex flex-col items-center justify-center w-full py-64 max-w-320">
								<FuseAnimate delay={300}>
									<div className="flex items-center mb-32">
										<img className="logo-icon w-48" src="assets/images/logos/fuse.svg" alt="logo" />
										<div className="border-l-1 mr-4 w-1 h-40" />
										<div>
											<Typography className="text-24 font-800 logo-text" color="inherit">
												Artemis
											</Typography>
											<Typography
												className="text-16 tracking-widest -mt-8 font-700"
												color="textSecondary"
											>
												{identifier}
											</Typography>
										</div>
									</div>
								</FuseAnimate>

								{this.props.children}
							</CardContent>

							<div className="flex flex-col items-center justify-center pb-32">
								<div>
									<span className="font-medium mr-8">&copy; 2023 Artemis ITS</span>
								</div>
							</div>
						</Card>

						<div
							className={clsx(
								classes.rightSection,
								'hidden md:flex flex-1 items-center justify-center p-64'
							)}
						>
							<div className="max-w-320">
								<FuseAnimate animation="transition.slideUpIn" delay={400}>
									<Typography variant="h3" color="inherit" className="font-800 leading-tight">
										Welcome to the <br />
										Artemis {identifier}
									</Typography>
								</FuseAnimate>

								<FuseAnimate delay={500}>
									<Typography variant="subtitle1" color="inherit" className="mt-32">
										Powerful and professional admin template for Web Applications, CRM, CMS, Admin
										Panels and more.
									</Typography>
								</FuseAnimate>
							</div>
						</div>
					</div>
				</FuseAnimate>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	login: state.auth.login
})

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			clearMessages
		},
		dispatch
	)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginView))
