import React from 'react'
import { Redirect } from 'react-router-dom'
import FuseUtils from '@fuse/utils'
import AuthConfig from 'app/main/auth/AuthConfig'
import LoginConfig from 'app/main/login/LoginConfig'
import AzureConfig from 'app/main/azure/AzureConfig'
import LogoutConfig from 'app/main/logout/LogoutConfig'
import ProfileConfig from 'app/main/profile/ProfileConfig'
import Auth2faConfig from 'app/main/auth2fa/Auth2faConfig'
import UsersConfig from 'app/main/users/UsersConfig'
import CompaniesConfig from 'app/main/companies/CompaniesConfig'
import DepartmentsConfig from 'app/main/departments/DepartmentsConfig'
import ApplicationsConfig from 'app/main/applications/ApplicationsConfig'
import IdentifierConfig from 'app/main/identifier/IdentifierConfig'
import ActivationConfig from 'app/main/activation/ActivationConfig'
import StatsConfig from 'app/main/stats/StatsConfig'

const routeConfigs = [
	AuthConfig,
	LoginConfig,
	AzureConfig,
	LogoutConfig,
	ProfileConfig,
	Auth2faConfig,
	UsersConfig,
	CompaniesConfig,
	DepartmentsConfig,
	ApplicationsConfig,
	IdentifierConfig,
	ActivationConfig,
	StatsConfig
]

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/profile" />
	},
	{
		component: () => <Redirect to="/error-404" />
	}
]

export default routes
