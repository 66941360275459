import { createSlice } from '@reduxjs/toolkit'
import apiService from 'app/services/apiService'
import history from '@history'
import { setAppRole, setRole } from './userSlice'

export const submitGetToken = code => async dispatch => {
	return apiService
		.getValidToken(code)
		.then(token => {
			apiService.setSession(token)
			apiService.emit('onAutoLogin', true)
			return dispatch(loginSuccess(true))
		})
		.catch(error => {
			return dispatch(loginError(true))
		})
}

export const submitLogin =
	({ email, password }, identifier) =>
	async dispatch => {
		return apiService
			.signInWithEmailAndPassword(email, password, identifier)
			.then(enable_2fa => {
				if (enable_2fa) {
					// .. enable pin to form
					return dispatch(loginSuccess(false))
				}

				// ..redirect to conf2fa
				dispatch(setAppRole(['conf2fa']))

				history.push({
					pathname: '/auth2fa'
				})
			})
			.catch(error => {
				console.log(error)
				// eslint-disable-next-line
				if (error.code === '01') {
					return dispatch(
						loginError({
							email: 'This User is not Active'
						})
					)
				}
				if (error.code === '02') {
					return dispatch(
						loginError({
							email: 'No access to current application allowed for this user.'
						})
					)
				}
				return dispatch(
					loginError({
						email: 'Check your username/email',
						password: 'Check your password'
					})
				)
			})
	}

export const submitPin =
	({ pin }) =>
	async dispatch => {
		return apiService
			.checkAuth2faPin(pin)
			.then(token => {
				// ..login action
				dispatch(setRole('admin'))
				apiService.setSession(token)

				apiService.emit('onAutoLogin', true)

				return dispatch(loginSuccess(true))
			})
			.catch(() => {
				return dispatch(
					loginError({
						pin: 'Pin is invalid'
					})
				)
			})
	}

export const sendUserVerification = username => async dispatch => {
	return apiService.sendNotification(username)
}

export const submitAzureToken = token => async dispatch => {
	return apiService
		.signInWithAzureToken(token)
		.then(() => {
			return dispatch(loginSuccess(true))
		})
		.catch(() => {
			return dispatch(loginError(true))
		})
}

const initialState = {
	credentials: {
		identity: null,
		secret: null
	},
	success: false,
	messages: [],
	pin: false,
	error: {
		username: null,
		password: null,
		pin: null
	}
}

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		setInitialLoginState: () => initialState,
		loginSuccess: (state, action) => {
			state.success = true
			state.pin = action.payload
			state.messages = action.payload
		},
		loginError: (state, action) => {
			state.success = false
			state.error = action.payload
		},
		clearMessages: (state, action) => {
			state.messages = []
		},
		resetLogin: (state, action) => {
			state.pin = false
			state.success = false
		}
	},
	extraReducers: {}
})

export const { setInitialLoginState, loginSuccess, loginError, clearMessages, resetLogin } = loginSlice.actions

export default loginSlice.reducer
