import Auth2fa from './Auth2fa'

const Auth2faConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: ['conf2fa'],
	routes: [
		{
			path: '/auth2fa',
			component: Auth2fa
		}
	]
}

export default Auth2faConfig
