import Departments from './Departments'
import globalConfig from '../../fuse-configs/globalConfig'

const DepartmentsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['admin'],
	routes: [
		{
			path: '/departments',
			component: Departments
		}
	]
}

export default DepartmentsConfig
