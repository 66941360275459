const navigationConfig = [
	{
		id: 'user',
		title: 'User',
		type: 'group',
		icon: 'people',
		children: [
			{
				id: 'profile-component',
				title: 'Profile',
				type: 'item',
				icon: 'people',
				url: '/profile'
			}
		]
	},
	{
		id: 'admin',
		title: 'Admin',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'users-component',
				auth: ['admin'],
				title: 'Users',
				type: 'item',
				icon: 'group',
				url: '/users'
			},
			{
				id: 'companies-component',
				auth: ['admin'],
				title: 'Companies',
				type: 'item',
				icon: 'business',
				url: '/companies'
			},
			{
				id: 'department-component',
				auth: ['admin'],
				title: 'Departments',
				type: 'item',
				icon: 'home_work',
				url: '/departments'
			},
			{
				id: 'applications-component',
				auth: ['admin'],
				title: 'Applications',
				type: 'item',
				icon: 'apps',
				url: '/applications'
			},
			{
				id: 'stats-component',
				auth: ['admin'],
				title: 'Stats',
				type: 'item',
				icon: 'assessment',
				url: '/stats'
			}
		]
	}
]

export default navigationConfig
