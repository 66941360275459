import FuseSplashScreen from '@fuse/core/FuseSplashScreen'
import apiService from 'app/services/apiService'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice'
import _ from '@lodash'
import { setUserData, logoutUser } from './store/userSlice'
import { socketGet } from 'app/SocketContext'

let socket = socketGet()
class Auth extends Component {
	state = {
		waitAuthCheck: true
	}

	componentDidMount() {
		this.socketAuthCheck()
		return Promise.all([
			// ..Comment the lines which you do not use
			// ..removed default services
			this.apiCheck()
		]).then(() => {
			const { userRole } = this.props
			if (!apiService.checkFiber(userRole)) {
				this.setState({ waitAuthCheck: false })
			}
		})
	}

	/**
	 * Socket IO
	 */
	socketAuthCheck = () => {
		socket.on('connect', () => {
			console.info('Socket connected', socket.id)
		})
		socket.on('disconnect', () => {
			console.info('Socket disconnected')
		})
		socket.on('connect_error', error => {
			console.error('Socket error', error)
		})
	}

	apiCheck = () =>
		new Promise(resolve => {
			apiService.on('onAutoLogin', () => {
				/**
				 * Sign in and retrieve user data from Api
				 */
				apiService
					.signInWithToken()
					.then(user => {
						const role = []
						role.push('user')
						_.each(user.applications, application => {
							switch (application) {
								case 'ArtemisID':
									role.push('admin')
									break
								case 'Fiber':
									role.push('fiber')
									break
								default:
							}
						})
						if (user.Is2FAConfigured) {
							if (!apiService.getAccessToken()) {
								this.props.showMessage({ message: 'Successfully logged in' })
							}
						} else {
							role.push('conf2fa')
						}

						const curSocket = socketGet()
						if (socket.id !== curSocket.id) {
							socket = curSocket
							this.socketAuthCheck()
						}

						const userData = {
							role: role,
							data: {
								displayName: user.FirstName + ' ' + user.LastName,
								firstName: user.FirstName,
								lastName: user.LastName,
								fathersName: user.FathersName,
								jobTitle: user.JobTitle,
								birthDate: user.BirthDate,
								photoURL: '',
								email: user.contact_details !== null ?? user.contact_details.Email,
								phone: true ?? user.contact_details.Phone,
								type: true ?? user.contact_details.Type,
								country: true ?? user.contact_details.Country,
								groups: user.group_members,
								settings: {},
								shortcuts: []
							}
						}

						if (apiService.getAccessToken()) {
							delete userData['redirectUrl']
						}

						this.props.setUserData(userData)

						resolve()
					})
					.catch(error => {
						this.props.showMessage({ message: error.message })

						resolve()
					})
			})

			apiService.on('onAutoLogout', message => {
				if (message) {
					this.props.showMessage({ message })
				}

				this.props.logout()
				resolve()
			})

			apiService.on('onNoAccessToken', () => {
				resolve()
			})

			apiService.init()

			return Promise.resolve()
		})

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			showMessage,
			hideMessage
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
