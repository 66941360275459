import { createSlice } from '@reduxjs/toolkit'
import history from '@history'
import _ from '@lodash'
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice'
import { setInitialLoginState } from 'app/auth/store/loginSlice'
import apiService from 'app/services/apiService'

export const setUserData = user => async dispatch => {
	/*
	You can redirect the logged-in user to a specific route depending on his role
	 */
	/*
	history.location.state = {
		redirectUrl: user.redirectUrl // for example 'apps/academy'
	};
	 */

	/*
    Set User Settings
     */
	dispatch(setDefaultSettings(user.data.settings))

	/*
	You can redirect the logged-in user to a specific route depending on his role
	*/
	if (user.hasOwnProperty('redirectUrl')) {
		history.push({
			pathname: user.redirectUrl
		})
	}

	dispatch(setUser(user))
}

export const setAppRole = role => async dispatch => {
	dispatch(setRole(role))
}

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user
	const user = _.merge({}, oldUser, { data: { settings } })

	// dispatch(updateUserData(user));

	return dispatch(setUserData(user))
}

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	}

	// dispatch(updateUserData(user));

	return dispatch(setUserData(newUser))
}

export const logoutUser = () => async (dispatch, getState) => {
	history.push({
		pathname: '/'
	})

	apiService.setSession(null)

	dispatch(setInitialSettings())
	dispatch(setInitialLoginState())

	return dispatch(userLoggedOut())
}

/*
  Logout
 */
const initialState = {
	role: [], // logout
	data: {} // remove all data
}

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState,
		setRole: (state, action) => {
			state.role = action.payload
		}
	},
	extraReducers: {}
})

export const { setUser, userLoggedOut, setRole } = userSlice.actions

export default userSlice.reducer
