import { TextFieldFormsy } from '@fuse/core/formsy'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Formsy from 'formsy-react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	loginError,
	loginSuccess,
	sendUserVerification,
	submitLogin,
	submitPin,
	resetLogin
} from 'app/auth/store/loginSlice'
import { Link } from 'react-router-dom'
import { getCookie } from 'tiny-cookie'
import { Box, CircularProgress } from '@material-ui/core'

const useFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {
		htmlElRef.current && htmlElRef.current.focus()
	}

	return [htmlElRef, setFocus]
}

const SingleLoginTab = props => {
	const dispatch = useDispatch()
	const login = useSelector(({ auth }) => auth.login)

	const [isFormValid, setIsFormValid] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [showPin, setShowPin] = useState(false)
	const [identifier, setIdentifier] = useState()
	const [username, setUsername] = useState(null)
	const [verifying, setVerifying] = useState(false)
	const [inputRef, setInputFocus] = useFocus()

	const formRef = useRef(null)

	useEffect(() => {
		if (login.error && (login.error.email || login.error.pin)) {
			formRef.current.updateInputsWithError({
				...login.error
			})
			disableButton()
		}
		return () => {
			dispatch(resetLogin())
		}
	}, [dispatch, login.error, login.messages])

	useEffect(() => {
		if (login.success && !login.pin) {
			setShowPin(true)
			setTimeout(() => {
				setInputFocus()
			}, 100)
			enableButton()
		}
	}, [login, setInputFocus])

	useEffect(() => {
		setIdentifier(getCookie('identifier'))
	}, [])

	const disableButton = () => {
		setIsFormValid(false)
	}

	const enableButton = () => {
		setIsFormValid(true)
	}

	const handleSubmit = model => {
		disableButton()
		dispatch(showPin ? submitPin(model) : submitLogin(model, identifier))
	}

	return (
		<div className="w-full">
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				<Box className="relative w-full">
					{verifying && <CircularProgress size={32} className="absolute top-1/3 left-1/2 -mt-12 -ml-12" />}
					<TextFieldFormsy
						className="mb-16 w-full"
						type="text"
						name="email"
						label="Username/Email"
						value={username}
						validations={{
							minLength: 4
						}}
						validationErrors={{
							minLength: 'Min character length is 4'
						}}
						InputProps={{
							disabled: !!showPin || !!verifying,
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										email
									</Icon>
								</InputAdornment>
							)
						}}
						onChange={e => {
							setUsername(e.target.value)
						}}
						variant="outlined"
						required
					/>
				</Box>

				<TextFieldFormsy
					className="mb-16"
					type="password"
					name="password"
					label="Password"
					validations={{
						minLength: 4
					}}
					validationErrors={{
						minLength: 'Min character length is 4'
					}}
					InputProps={{
						className: 'pr-2',
						type: showPassword ? 'text' : 'password',
						disabled: showPin,
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)}>
									<Icon className="text-20" color="action">
										{showPassword ? 'visibility' : 'visibility_off'}
									</Icon>
								</IconButton>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				{showPin && (
					<TextFieldFormsy
						className="mb-16"
						type="text"
						name="pin"
						label="Pin"
						value=""
						validations={{
							minLength: 6
						}}
						validationErrors={{
							minLength: 'Min character length is 6'
						}}
						ref={inputRef}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										phonelink_lock
									</Icon>
								</InputAdornment>
							)
						}}
						variant="outlined"
						required
					/>
				)}

				<Button
					type="submit"
					name="button"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16 normal-case"
					aria-label="LOG IN"
					disabled={!isFormValid}
					value="legacy"
				>
					Login
				</Button>
			</Formsy>

			<div className="flex flex-col items-center justify-center pb-32">
				<div>
					<span className="font-medium mr-8">Forgot your password?</span>
					<Link
						to="/#"
						className="font-medium"
						onClick={e => {
							e.preventDefault()
							if (!username) {
								dispatch(
									loginError({
										email: 'Email is required to reset your password'
									})
								)
							} else {
								setVerifying(true)
								dispatch(sendUserVerification(username))
									.then(response => {
										setVerifying(false)
										dispatch(loginSuccess(['Please check your email to reset your password']))
									})
									.catch(error => {
										dispatch(
											loginError({
												email: error.error
											})
										)
										setVerifying(false)
									})
							}
						}}
					>
						Reset
					</Link>
				</div>
			</div>
		</div>
	)
}

export default SingleLoginTab
