import Users from './Users'
import globalConfig from 'app/fuse-configs/globalConfig'

const UsersConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['admin'],
	routes: [
		{
			path: '/users',
			component: Users
		}
	]
}

export default UsersConfig
