import React from 'react'
import DataGrid, { Editing, Scrolling, RemoteOperations, Column, Lookup } from 'devextreme-react/data-grid'
import { DataView, DataStyles } from 'app/data-grid'
import apiService from 'app/services/apiService'

const dataStore = apiService.createDataCustomStore('DepartmentID', 'departments')
const companies = apiService.createRowCustomStore('CompanyID', 'companies')

function Departments(props) {
	const classes = DataStyles()

	return (
		<DataView classes={classes} icon={`home_work`} title="Departments">
			<DataGrid
				style={{
					width: '100%',
					height: '100%'
				}}
				key="id"
				id="gridContainer"
				dataSource={dataStore}
				showBorders={true}
			>
				<Editing mode="table" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} />

				<RemoteOperations groupPaging={true} />
				<Scrolling mode="virtual" />

				<Column dataField="DepartmentID" caption="ID" dataType="number" width={75} allowEditing={false} />
				<Column dataField="DepartmentDescription" caption="Name" dataType="string" />
				<Column dataField="CompanyID" caption="Company">
					<Lookup dataSource={companies} valueExpr="CompanyID" displayExpr="CompanyDescription" />
				</Column>
			</DataGrid>
		</DataView>
	)
}

export default Departments
