const globalConfig = {
	toolbar: {
		display: false
	},
	footer: {
		display: false
	}
}

export default globalConfig
