import Button from '@material-ui/core/Button'
import React from 'react'

function MicrosoftLoginTab(props) {
	function handleClick() {
		window.location.replace(process.env.REACT_APP_API_ID_URL + '/auth/azure/login?tracking=1')
	}

	return (
		<div className="w-full">
			<Button className="w-full my-48" color="primary" variant="contained" onClick={handleClick}>
				Log In with Microsoft
			</Button>
		</div>
	)
}

export default MicrosoftLoginTab
