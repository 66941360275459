import FuseUtils from '@fuse/utils/FuseUtils'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import CustomStore from 'devextreme/data/custom_store'
import { getCookie, removeCookie } from 'tiny-cookie'
// eslint-disable camelcase
class ApiService extends FuseUtils.EventEmitter {
	apiUrl = process.env.REACT_APP_API_ID_URL
	accessToken = null
	accessCode = null

	init() {
		this.handleAuthentication()
	}

	handleAuthentication = () => {
		const access_token = this.getAccessToken()

		if (!access_token) {
			this.emit('onNoAccessToken')
			return
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token)
			this.emit('onAutoLogin', true)
		} else {
			// this.logout({error: 'Unauthorized'});
			this.setSession(null)
			this.emit('onAutoLogout', 'access_token expired')
		}
	}

	signInWithEmailAndPassword = (email, password, identifier) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/auth/login?tracking=1`, {
					username: email,
					password: password,
					cookie: identifier
				})
				.then(response => {
					this.accessToken = response.data.auth.access_token // ..store token
					this.accessCode = response.data.auth.access_code // ..store code

					resolve(response.data.auth.enable_2fa)
				})
				.catch(error => {
					reject(error.response.data)
				})
		})
	}

	getRedirectAuthUrl = identifier => {
		if (identifier === 'pulse') {
			return process.env.REACT_APP_PULSE_URL
		}
		if (identifier === 'legacy') {
			return process.env.REACT_APP_LEGACY_URL
		}
		if (identifier === 'fiber') {
			return process.env.REACT_APP_FIBER_URL
		}
		return ''
	}

	signInWithAzureToken = code => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/auth/azure/token/${code}`)
				.then(response => {
					const identifier = getCookie('identifier')
					if (identifier) {
						removeCookie('identifier')
						const url = this.getRedirectAuthUrl(identifier)
						window.location.replace(url + '/auth/' + response.data.auth.access_code)
					} else {
						localStorage.setItem('access_code', response.data.auth.access_code)
						this.setSession(response.data.auth.access_token)
						this.emit('onAutoLogin', true)
						resolve()
					}
				})
				.catch(error => {
					reject()
				})
		})
	}

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/auth/me?full=1&tracking=1`)
				.then(response => {
					resolve(response.data.user)
				})
				.catch(error => {
					this.logout(error)
					reject(new Error('Failed to login'))
				})
		})
	}

	codeVerification = code => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/activation/code`, {
					code: code
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(new Error('Code Expired'))
				})
		})
	}

	resetPassword = (password, userID) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/activation/reset`, {
					UserID: userID,
					password: password
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {})
		})
	}

	sendNotification = username => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/user/verification`, {
					username: username
				})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error.response.data)
				})
		})
	}

	checkAuth2faPin = pin => {
		return new Promise((resolve, reject) => {
			// ..pass token
			axios.defaults.headers.common.Authorization = `bearer ${this.accessToken}`
			axios
				.post(`${this.apiUrl}/auth/2fa/pin`, {
					pin: pin
				})
				.then(response => {
					if (response.data.success) {
						const identifier = getCookie('identifier')
						if (identifier) {
							removeCookie('identifier')
							const url = this.getRedirectAuthUrl(identifier)
							window.location.replace(url + '/auth/' + this.accessCode)
						} else {
							resolve(this.accessToken)
						}
					} else {
						reject()
					}
				})
		})
	}

	qr2fa = () => {
		return new Promise((resolve, reject) => {
			axios.defaults.headers.common.Authorization = `bearer ${this.accessToken}`

			axios
				.get(`${this.apiUrl}/auth/2fa/qr`, {
					responseType: 'arraybuffer'
				})
				.then(response => {
					const base64 = btoa(
						new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
					)
					resolve(base64)
				})
		})
	}

	loadData = (model, options) => {
		const isNotEmpty = value => {
			return value !== undefined && value !== null && value !== ''
		}

		let params = '?'
		;['skip', 'take', 'requireTotalCount', 'sort', 'filter'].forEach(i => {
			if (i in options && isNotEmpty(options[i])) {
				params += `${i}=${JSON.stringify(options[i])}&`
			}
		})

		params = params.slice(0, -1)

		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/store/${model}/data${params}`)
				.then(response => response.data)
				.then(data => {
					resolve({
						data: data.data,
						totalCount: data.totalCount
					})
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	getData = (model, key) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/store/${model}/data/${key}`)
				.then(response => response.data)
				.then(data => {
					resolve({
						data: data.data
					})
				})
				.catch(error => {
					this.logout(error)
					reject()
				})
		})
	}

	getValidToken = code => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/auth/token/${code}`)
				.then(response => {
					resolve(response.data.auth.access_token)
				})
				.catch(error => {
					reject()
				})
		})
	}

	insertData = (model, values) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/store/${model}/data`, values)
				.then(response => response.data)
				.then(() => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	updateData = (model, key, values) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/store/${model}/data/${key}`, values)
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	deleteData = (model, key) => {
		return new Promise((resolve, reject) => {
			axios
				.delete(`${this.apiUrl}/store/${model}/data/${key}`)
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					this.logout(error)
					reject()
				})
		})
	}

	createDataCustomStore = (keyField, model) => {
		const apiService = this

		return new CustomStore({
			key: keyField,
			byKey: key => {},
			load: loadOptions => {
				return apiService.loadData(model, loadOptions)
			},
			insert: values => {
				console.log('insert-values-' + model, values)
				return apiService.insertData(model, values)
			},
			update: (key, values) => {
				console.log('update-key-' + model, key)
				console.log('update-values-' + model, values)
				return apiService.updateData(model, key, values)
			},
			remove: key => {
				console.log('remove-key-' + model, key)
				return apiService.deleteData(model, key)
			}
		})
	}

	createRowCustomStore = (keyField, model) => {
		return new CustomStore({
			key: keyField,
			cacheRawData: false,
			loadMode: 'raw',
			load: () => {
				return new Promise((resolve, reject) => {
					axios
						.get(`${this.apiUrl}/store/${model}/data`)
						.then(response => response.data)
						.then(data => {
							resolve(data.data)
						})
						.catch(error => {
							this.logout(error)
							reject()
						})
				})
			}
		})
	}

	// ===== async validation request =====
	sendRequest = (username, UserID) => {
		return new Promise((resolve, reject) => {
			if (UserID) {
				resolve()
				return
			}
			axios
				.get(`${this.apiUrl}/validate/username/${username}`)
				.then(response => response.data)
				.then(() => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	getUsersSessions = qs => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/stats/users/sessions?${qs}`)
				.then(response => response.data)
				.then(data => {
					resolve({
						data: data.data
					})
				})
				.catch(error => {
					reject()
				})
		})
	}

	getQueriesSessions = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/stats/queries/sessions`)
				.then(response => response.data)
				.then(data => {
					resolve({
						data: data.data
					})
				})
				.catch(error => {
					reject()
				})
		})
	}

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			axios.defaults.headers.common.Authorization = `bearer ${access_token}`
		} else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common.Authorization
		}
	}

	logout = payload => {
		if (payload.error === 'Unauthorized') {
			this.setSession(null)
			this.emit('onAutoLogout')
		}
	}

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false
		}
		const decoded = jwtDecode(access_token)
		const currentTime = Date.now() / 1000
		if (decoded.exp < currentTime) {
			console.warn('access token expired')
			return false
		}

		return true
	}

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token')
	}

	checkFiber = role => {
		if (role.indexOf('admin') === -1 && role.indexOf('fiber') > -1) {
			this.setSession(null)
			const token = localStorage.getItem('access_code')
			window.location.replace(process.env.REACT_APP_FIBER_URL + '/auth/' + token)
			return true
		}
		return false
	}
}

const instance = new ApiService()

export default instance
