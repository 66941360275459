import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import React from 'react'
import { useForm } from '@fuse/hooks'
import { Link } from 'react-router-dom'
import apiService from 'app/services/apiService'
import history from '@history'
import { bindActionCreators } from '@reduxjs/toolkit'
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice'
import { connect } from 'react-redux'
import { setCookie } from 'tiny-cookie'

const Form = props => {
	const { form, handleChange, resetForm } = useForm({
		password: '',
		passwordConfirm: ''
	})

	const isFormValid = () => {
		return form.password.length > 0 && form.password.length > 3 && form.password === form.passwordConfirm
	}

	const handleSubmit = ev => {
		ev.preventDefault()
		apiService
			.resetPassword(form.password, props.data.UserID)
			.then(response => {
				props.showMessage({ message: response.message })
				if (response.applications.indexOf('Fiber') > -1) {
					setCookie('identifier', 'fiber')
				}
				if (response.applications.indexOf('Pulse') > -1) {
					setCookie('identifier', 'pulse')
				}
				history.push({
					pathname: '/login'
				})
			})
			.catch(error => {})
		resetForm()
	}

	return (
		<React.Fragment>
			<form name="resetForm" noValidate className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
				<TextField
					className="mb-16"
					label="Password"
					type="password"
					name="password"
					value={form.password}
					onChange={handleChange}
					variant="outlined"
					required
					fullWidth
				/>

				<TextField
					className="mb-16"
					label="Password (Confirm)"
					type="password"
					name="passwordConfirm"
					value={form.passwordConfirm}
					onChange={handleChange}
					variant="outlined"
					required
					fullWidth
				/>

				<Button
					variant="contained"
					color="primary"
					className="w-224 mx-auto mt-16"
					aria-label="Reset"
					disabled={!isFormValid()}
					type="submit"
				>
					SETUP MY PASSWORD
				</Button>
			</form>
			<div className="flex flex-col items-center justify-center pt-32 pb-24">
				<Link className="font-medium" to="/login">
					Go back to login
				</Link>
			</div>
		</React.Fragment>
	)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			showMessage,
			hideMessage
		},
		dispatch
	)
}

export default connect(null, mapDispatchToProps)(Form)
