import FuseLoading from '@fuse/core/FuseLoading'
import React, { Component } from 'react'
import apiService from 'app/services/apiService'

class QR2FA extends Component {
	state = {
		source: null,
		waitImageLoad: true
	}

	componentDidMount() {
		apiService.qr2fa().then(base64 => {
			this.setState({ source: 'data:;base64,' + base64, waitImageLoad: false })
		})
	}

	render() {
		return this.state.waitImageLoad ? <FuseLoading /> : <img alt="QR" src={this.state.source} />
	}
}

export default QR2FA
