import FuseAnimate from '@fuse/core/FuseAnimate'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Promise from 'lodash/_Promise'
import apiService from 'app/services/apiService'

import Form from './Form'
import Loading from './Loading'
import FuseLoading from '@fuse/core/FuseLoading'

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}))

const Activation = () => {
	const classes = useStyles()
	const routeParams = useParams()

	const verificationCode = routeParams.verification

	const [wait, setWait] = useState(true)
	const [details, setDetails] = useState({})
	const [expired, setExpired] = useState(false)

	const apiCheck = () => {
		new Promise(resolve => {
			/**
			 * Sign in and retrieve user data from Api
			 */
			apiService
				.codeVerification(verificationCode)
				.then(details => {
					setWait(false)
					setDetails(details.data)

					resolve()
				})
				.catch(error => {
					setWait(false)
					setExpired(true)

					resolve()
				})

			return Promise.resolve()
		})
	}

	useEffect(() => {
		if (wait) {
			apiCheck()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	if (expired) {
		return <Loading />
	}

	if (wait) {
		return <FuseLoading />
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
				<FuseAnimate animation="transition.expandIn">
					<img className="w-128 mb-32" src="assets/images/logos/fuse.svg" alt="logo" />
				</FuseAnimate>

				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Typography variant="h3" color="inherit" className="font-light">
						Account Activation..
					</Typography>
				</FuseAnimate>

				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
						Instructions on how to activate your account have beem emailed to you. Please check your email.
					</Typography>
				</FuseAnimate>
			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128">
						<Typography variant="h5" className="md:w-full mb-32">
							Hey {details.FirstName},
						</Typography>
						<Typography variant="h6" className="md:w-full mb-32">
							Please SetUp Your Account Password
						</Typography>
						<Form data={details} />
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	)
}

export default Activation
