import React from 'react'
import globalConfig from '../../fuse-configs/globalConfig'

const ProfileConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['user'],
	routes: [
		{
			path: '/profile',
			component: React.lazy(() => import('./Profile'))
		}
	]
}

export default ProfileConfig
