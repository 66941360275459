import React from 'react'
import FuseAnimate from '@fuse/core/FuseAnimate'
import Typography from '@material-ui/core/Typography'

const Loading = props => {
	return (
		<div className="flex flex-col flex-1 items-center justify-center p-16">
			<div className="max-w-512 text-center">
				<FuseAnimate animation="transition.expandIn" delay={100}>
					<Typography variant="h1" color="inherit" className="font-medium mb-16">
						Expired
					</Typography>
				</FuseAnimate>

				<FuseAnimate delay={500}>
					<Typography variant="h5" color="textSecondary" className="mb-16">
						Sorry, this Link has been expired or is invalid
					</Typography>
				</FuseAnimate>
			</div>
		</div>
	)
}

export default Loading
