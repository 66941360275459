import store from 'app/store'
import { logoutUser } from 'app/auth/store/userSlice'

const LogoutConfig = {
	auth: null,
	routes: [
		{
			path: '/logout',
			component: () => {
				store.dispatch(logoutUser())
				return 'Logging out..'
			}
		}
	]
}

export default LogoutConfig
