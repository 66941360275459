import React from 'react'
import { useDispatch } from 'react-redux'
import { LoginView, LoginStyles } from 'app/auth'
import FuseLoading from '@fuse/core/FuseLoading'
import { submitAzureToken } from 'app/auth/store/loginSlice'
import apiService from 'app/services/apiService'

function Azure() {
	const dispatch = useDispatch()
	const classes = LoginStyles()

	const path = window.location.pathname.split('/')
	const code = path.length > 1 ? path[2] : ''

	if (!apiService.getAccessToken() && code) {
		dispatch(submitAzureToken(code))
	}

	return (
		<LoginView classes={classes}>
			<FuseLoading />
		</LoginView>
	)
}

export default Azure
