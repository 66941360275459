import Applications from './Applications'
import globalConfig from '../../fuse-configs/globalConfig'

const ApplicationsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['admin'],
	routes: [
		{
			path: '/applications',
			component: Applications
		}
	]
}

export default ApplicationsConfig
