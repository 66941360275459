import Activation from './Activation'

const ActivationConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: [],
	routes: [
		{
			path: '/activation/:verification',
			component: Activation
		},
		{
			path: '/passwordreset/:verification',
			component: Activation
		}
	]
}

export default ActivationConfig
