import React from 'react'
import { useDispatch } from 'react-redux'
import FuseLoading from '@fuse/core/FuseLoading'
import { logoutUser } from 'app/auth/store/userSlice'
import { setCookie } from 'tiny-cookie'
import history from '@history'

function Identifier() {
	const dispatch = useDispatch()

	const path = window.location.pathname.split('/')
	const identifier = path.length > 1 ? path[2] : ''

	if (identifier === 'pulse' || identifier === 'legacy' || identifier === 'fiber') {
		setCookie('identifier', identifier)
		setCookie('prev_url', history.location.pathname)
	}
	dispatch(logoutUser())

	return <FuseLoading />
}

export default Identifier
