import React from 'react'
import globalConfig from '../../fuse-configs/globalConfig'

const StatsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['admin'],
	routes: [
		{
			path: '/stats',
			component: React.lazy(() => import('./Stats'))
		}
	]
}

export default StatsConfig
