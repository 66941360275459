import { TextFieldFormsy } from '@fuse/core/formsy'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import Formsy from 'formsy-react'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { QR2FA, LoginView, LoginStyles } from 'app/auth'
import { submitPin } from 'app/auth/store/loginSlice'
import { Link } from 'react-router-dom'

function Auth2fa() {
	const classes = LoginStyles()
	const dispatch = useDispatch()
	const login = useSelector(({ auth }) => auth.login)

	const [isFormValid, setIsFormValid] = useState(false)

	const formRef = useRef(null)

	useEffect(() => {
		if (login.error && login.error.pin) {
			formRef.current.updateInputsWithError({
				...login.error
			})
			disableButton()
		}
	}, [login.error])

	const disableButton = () => {
		setIsFormValid(false)
	}

	const enableButton = () => {
		setIsFormValid(true)
	}

	const handleSubmit = model => {
		disableButton()

		dispatch(submitPin(model))
	}

	return (
		<LoginView classes={classes}>
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				<QR2FA />

				<TextFieldFormsy
					className="mb-16"
					type="text"
					name="pin"
					label="Pin"
					value=""
					validations={{
						minLength: 6
					}}
					validationErrors={{
						minLength: 'Min character length is 6'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									phonelink_lock
								</Icon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16 normal-case"
					aria-label="CONNECT"
					disabled={!isFormValid}
					value="legacy"
				>
					Connect
				</Button>

				<div className="flex flex-col items-center justify-center pt-32">
					<Link className="font-medium mt-8" to="/logout">
						Login
					</Link>
				</div>
			</Formsy>
		</LoginView>
	)
}

export default Auth2fa
